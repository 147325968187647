<template>
  <div class="container">
    <div class="row center-xs">
      <h2 class="my40 main-title">
        {{ $t("What can I sell at E-Garderobe?") }}
      </h2>
    </div>
    <div class="row center-xs">
      <div class="col-xs-12 col-md-5 col-lg-5 mb20 mr20 action-block">
        <div>
          <h2 class="action-block-title mt0">
            {{ $t("Unworn fashion treasures pilled in your wardrobe? Sell it fast and make some extra money!") }}
          </h2>
          <p class="action-block-description">
            {{ $t("Selling pre-loved fashion at E-Garderobe is very easy and smooth") }}
          </p>
          <div class="row center-xs mt20">
            <a :href="localizedRoute(getMarketplaceDesignerCategory)">
              <button-full class="button-full">
                {{ $t("Discover our luxury designers and brands") }}
              </button-full>
            </a>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-md-3 col-lg-3 p0 pr10 mb20 order-2">
        <div class="image-container small-image">
          <img class="pr20 image" v-lazy="'/assets/co-moge-sprzedac-1.jpg'" alt="1">
        </div>
      </div>
      <div class="col-xs-6 col-md-3 col-lg-3 p0 pl10 mb20 order-1">
        <div class="image-container small-image">
          <img class="image" v-lazy="'/assets/co-moge-sprzedac-2.jpg'" alt="2">
        </div>
      </div>
    </div>
    <div class="row center-xs">
      <div class="col-xs-12 col-md-5 col-lg-5 p0 mb20 mr20">
        <div class="image-container big-image">
          <img class="image" v-lazy="'/assets/chronimy-planete.png'" alt="">
        </div>
      </div>
      <div class="col-xs-12 col-md-6 col-lg-6 mb20 color-block">
        <h2 class="color-block-title">
          {{ $t("Extend the life of luxury contributing in more sustainable future") }}
        </h2>
      </div>
    </div>
    <div class="row center-xs">
      <h2 class="faq-title mb10">
        {{ $t("Have more questions?") }}
      </h2>
    </div>
    <div class="row center-xs">
      <a class="underline-link" :href="$t('https://faq.e-garderobe.com')">
        {{ $t("Browse our FAQ") }}
      </a>
    </div>
  </div>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import { mapGetters } from 'vuex'
import config from 'config'

export default {
  name: 'WhatCanISellBlock',
  components: {
    ButtonFull
  },
  computed: {
    ...mapGetters('category-next', ['getMenuCategories']),
    getMarketplaceDesignerCategory () {
      const designerRoot = this.getMenuCategories.find(cat => cat.id === config.server.categoryIdsMap.rootDesignerCategoryId)
      const marketplaceDesigner = designerRoot && designerRoot.children_data.find(cat => cat.id === config.server.categoryIdsMap.marketplaceDesignerCategoryId)
      return marketplaceDesigner && marketplaceDesigner.url_path
    }
  }
}
</script>

<style lang="scss" scoped>
  $color-yellow: #F4FCE5;
  $color-gray: #F5F5F5;

  .main-title {
    font-size: 35px;
    font-weight: 400;
    @media (max-width: 992px) {
      font-size: 22px;
    }
    @media (max-width: 768px) {
      letter-spacing: 0.89px;
      margin: 0 0 30px 0;
    }
  }
  .faq-title {
    font-size: 35px;
    font-weight: 400;
    @media (max-width: 992px) {
      font-size: 22px;
    }
    @media (max-width: 768px) {
      letter-spacing: 0.89px;
    }
  }
  .action-block {
    display: flex;
    align-items: center;
    padding: 0 8%;
    background-color: $color-gray;
    @media (max-width: 768px) {
      padding: 30px 8%;
    }
  }
  .action-block-title {
    font-size: 20px;
    font-weight: bold;
    font-family: 'Poppins';
    text-transform: uppercase;
    letter-spacing: 1.48px;
    @media (max-width: 992px) {
      font-size: 14px;
      letter-spacing: 1.04px;
    }
    @media (max-width: 768px) {
      font-size: 12px;
      letter-spacing: 0.89px;
    }
  }
  .action-block-description {
    font-size: 12px;
    line-height: 18px;
  }
  .button-full {
    @media(max-width: 992px) {
      font-size: 12px;
    }
  }
  .color-block {
    display: flex;
    padding: 0 13%;
    background-color: $color-yellow;
    @media (max-width: 768px) {
      padding: 55px 13%;
    }
  }
  .color-block-title {
    margin: auto;
    text-align: center;
    letter-spacing: 1.7px;
    font-size: 23px;
    font-weight: 500;
    font-style: italic;
    line-height: 30px;
  }
  .img-block {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }
  .order-1 {
    order: unset;
    @media (max-width: 768px) {
      order: -1;
    }
  }
  .order-2 {
    order: unset;
    @media (max-width: 768px) {
      order: -2;
    }
  }
  .underline-link {
    margin-bottom: 40px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1.48px;
    text-transform: uppercase;
  }
  .image-container {
    position: relative;
    height: 0;
    &.big-image {
      padding-top: 97%;
    }
    &.small-image {
      padding-top: 142.4%;
    }
  }
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>
